@import url("https://fonts.googleapis.com/css2?family=Raleway:wght@600&display=swap");
/* :root{ 
  --main-color: red;
  --blue: blue;
  --blue-dark: #18293c;
  --orange: orange;
  --green-yellow: #cddc39;
  --pink-light: #efa2b4;
  --cyan-light: #aef1ee;
  --white: #ffffff;
  --white-alpha-40: rgba(255, 255, 255, 0.40);
  --white-alpha-25: rgba(255, 255, 255, 0.25);
  --backdrop-filter-blur: blur(5px);
} */

html,
body {
  font-family: "Raleway", sans-serif;
  /* background: #ffffff; */
  background-color: #1b1c1d;
  color: white;
  cursor: none;
  scroll-behavior: smooth;
}

div {
  margin-bottom: -12px;
}
.cursor {
  pointer-events: none;
}

.cursor__ball {
  position: absolute;
  top: 0;
  left: 0;
  mix-blend-mode: difference;
  z-index: 1000;
}

.cursor__ball circle {
  fill: #ffd900;
}
